/*thankyou-page*/

.thankyouPage {
  background-color: rgb(243, 247, 254);
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
}

.thankyouPageInner {
  background: rgb(255, 255, 255);
  border-radius: 90px;
  width: 100%;
  position: relative;
  top: 30%;
  /*transform: translateY(50%);*/
  text-align: center;
}

.wrapper {
  padding: 80px 90px;

  .tick {
    position: relative;

    .doneTick {
      width: 120px;
      height: 120px;
      border: solid 1px transparent;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      border-left-color: rgb(34, 208, 136);
      animation: rotateCircle 0.5s linear forwards;
      animation-delay: 0.5s;
      border-radius: 50%;
      background-color: transparent;
    }

    svg {
      font-weight: 900;
      font-size: 50px;
      color: rgb(34, 208, 136);
      position: absolute;
      animation: tickIcon 0.8s linear forwards;
      animation-delay: 0.5s;
      top: 30%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      animation-delay: 0.5s;
    }
  }

  h2 {
    font-size: 45px;
    color: rgb(5, 17, 51);
    margin-top: 20px;
    font-weight: bold;
  }
}

/*laptops/tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 1024px) {
  .wrapper {
    padding: 50px;
  }

  .thankyouPage {
    border-radius: 0;
    padding: 20px;
  }
  .thankyouPageInner {
    top: 5%;
  }

  .wrapper h2 {
    font-size: 30px;
  }
}

/*tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
  .wrapper {
    padding: 50px 80px !important;
  }
}

/*Smart phone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
  .wrapper {
    padding: 50px;
  }
  .thankyouPage {
    border-radius: 0;
    padding: 20px;
  }
  .thankyouPageInner {
    top: 15%;
    border-radius: 10px;
  }
  .wrapper h2 {
    font-size: 30px;
  }
}

@keyframes rotateCircle {
  25% {
    border-left-color: rgb(34, 208, 136);
  }
  50% {
    border-left-color: rgb(34, 208, 136);
    border-top-color: rgb(34, 208, 136);
  }
  75% {
    border-left-color: rgb(34, 208, 136);
    border-top-color: rgb(34, 208, 136);
    border-right-color: rgb(34, 208, 136);
  }
  100% {
    transform: rotate(360deg);
    border-color: rgb(34, 208, 136);
  }
}

@keyframes tickIcon {
  0% {
    font-size: 0;
    opacity: 1;
    top: 50%;
  }
  20% {
    font-size: 10px;
    top: 45%;
  }
  40% {
    font-size: 20px;
    top: 40%;
  }
  70% {
    font-size: 200px;
    top: 0;
  }
  100% {
    font-size: 50px;
    opacity: 1;
    top: 30%;
  }
}
