$selectColor: aliceblue;

.graphBg{
    background-image: url(https://digivents.s3-eu-west-1.amazonaws.com/IASP_SITE/1e55e07c-aa94-4a6d-bd91-8b10c3732991.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}


.tableAnswers {
    tr {
        cursor: pointer;
        &:hover {
            td,
            th {
                background-color: $selectColor;
            }
        }
    }

    .trSelected {
        td,
        th {
            background-color: $selectColor;
        }
    }
}
