$primaryColor: #003B79;
$primaryColorDisabled: #0759b0;
$secondaryColor: rgba(217,224,33,1);


$verdeAcido: #D9E020;

$blu:#003B79; 
$azzurro: #B0CEEC;
$arancio: #EE7D11;
$giallo:#D9E021;
$grigio:#DDDDDD ;