@import "../../assets/css/variables.scss";

.bodyBg{
    background-image: url(https://digivents.s3-eu-west-1.amazonaws.com/IASP_SITE/1e55e07c-aa94-4a6d-bd91-8b10c3732991.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}


.rowTitle{
    height: 24vh;
    position: relative; 
    z-index: 1;
}
.rowBody{
    height: 70vh;
}


.questionTitle{
    display: inline-block;
    background-color: $verdeAcido;
    color: white;
    padding: 20px 4vw;
    text-align: center;
    border-radius: 4em;
    
    span {
        margin-right: 20px;
        color: $blu;
        font-weight: bold;
    }
}