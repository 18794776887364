@import "../../assets/css/variables.scss";

.steps {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    background-color: transparent;
    display: grid;
    //place-items: center;
    position: relative;
    background-image: url("https://digivents.s3-eu-west-1.amazonaws.com/IASP_SITE/ef02cf17-5975-46c5-a45d-1388cf8b27a2.jpg");
    background-position: bottom center;

}

.stepsInner {
    min-height: 300px;
    border-radius: 9px;
    background-color: transparent;
    overflow: hidden;
    z-index: 5;
    position: relative;
    margin: 10px 0;
}

.wrapper {
    padding: 1em 0em;
}
.stepHeading {
    h3 {
        //font-size: 35px;
        color: $blu;
        font-weight: bold;
        text-transform: uppercase;
    }

    p {
        //font-size: 22px;
        color: rgb(0, 10, 56);
    }
}

.formInner {
    margin-top: 1rem;
}

  
.formButtons {
    text-align:  right;
    width: 100%;
    margin-top: 1rem;
    margin-right: 10px;

    button {
        border-radius: 15px;
        width: 130px;
        height: 45px;
        border: none;
        font-size: 18px;
        color: rgb(255, 255, 255);
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        transition: 0.5s all ease;

        svg {
            font-size: 15px;
            color: rgb(255, 255, 255);
            margin-left: 10px;
            margin-right: 10px;
            position: relative;
            transition: 0.5s all ease;
        }
    }

    .next {
        background-color: $primaryColor;

        &:disabled{

            &::after{
                content: ' ';
                width: 100%;
                height: 100%;
                background-color: white;
                opacity: 0.3;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 15px;
            }
        }

        &:hover {
            svg {
                transform: translateX(10px);
            }
        }
    }
}

.formHeading {
    margin-top: 20px;
    font-size: 1.3em;
    text-align: center;
}

@media (min-device-width: 1600px) {
}

/*L-laptops*/
@media only screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
}

/*laptops/tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 1024px) {
    // .wrapper {
    //     padding: 50px 80px;
    // }

    .formButtons button {
        font-size: 12px;
        height: 55px;
    }
    .formButtons button i {
        font-size: 12px;
    }
}

/*tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
    .stepsInner {
        margin-top: 100px;
    }
    // .wrapper {
    //     padding: 50px 80px;
    // }
}

/*Smart phone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .stepsInner {
        margin-top: 100px;
    }
    // .wrapper {
    //     padding: 30px;
    // }
    .formInput {
        font-size: 15px;
    }
    .formInput input {
        width: 25px;
        height: 25px;
    }
    .formInput input:checked::after {
        width: 19px;
        height: 19px;
        top: 3px;
        left: 3px;
    }
    .formButtons {
        display: block;
    }
    .formButtons button {
        font-size: 12px;
        height: 55px;
        width: 100%;
        margin-top: 10px;
    }
    .formButtons button i {
        font-size: 12px;
    }
} 