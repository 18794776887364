@import "../../../assets/css/variables.scss";

.formInner {
    margin-top: 1rem;

    &.showResult {
        pointer-events: none;
        .isCorrect {
            color: rgb(34, 208, 136);
        }

        .isError.activeInput {
            color: rgb(244, 0, 0);
        }
    }
}

.formInput {
    display: block;
    display: flex;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    min-height: 60px;
    font-size: 19px;
    color: $blu;
    font-weight: 500;
    padding: 12px;
    transition: 0.5s all ease;
    background-color: $grigio;
    margin: 10px 0px;

    span.questionIndex{
        margin-right: 10px;
    }

    span.questionText {
        flex: 1;
    }

    input {
        display: none;

        &[type="radio"] {
            border-radius: 50%;
        }
    }
     
    &.activeInput {
        background-color: $giallo;
       
        span.questionText {
            transition: 0.5s all ease;
            padding-left: 10px !important;
        }
    }
}

@media (min-device-width: 1600px) {
}

/*L-laptops*/
@media only screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
}

/*laptops/tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 1024px) {
}

/*tablets*/
@media only screen and (min-device-width: 576px) and (max-device-width: 768px) {
}

/*Smart phone*/
@media only screen and (min-device-width: 320px) and (max-device-width: 576px) {
    .formInput {
        font-size: 15px;
    }
    .formInput input {
        width: 25px;
        height: 25px;
    }
    .formInput input:checked::after {
        width: 19px;
        height: 19px;
        top: 3px;
        left: 3px;
    }
}
