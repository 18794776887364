.uiConfirm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    font-family: inherit;
    overflow: hidden;

    .uiConfirmBg {
        background-color: rgba(54, 70, 93, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: opacity 0.4s;
        transition: opacity 0.4s;
        width: 100%;
        height: 100%;
    }

    .uiContent {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: fixed;

        

        & > div {
            color: white;
            text-align: center;

            .confirmBox {
                border-radius: 4px;
                position: relative;
                outline: none;
                padding: 15px 15px 0;
                overflow: hidden;
                margin-left: auto;
                margin-right: auto;

                // animation-name: enter;
                // animation-duration: .5s;
                 

                .title {
                    text-align: center;
                    font-size: 28px;
                    font-weight: normal;
                    padding-bottom: 25px;
                    user-select: none;
                    line-height: 1;
                    display: inline-block;
                }

                .content {
                    font-size: 1.4rem;
                    text-align: center;
                    padding-bottom: 25px;
                }

                .contentButton {
                    padding-bottom: 11px;

                    button {
                        display: inline-block;
                        font-size: 16px;
                        border-radius: 2px;
                        background: #303f53;
                        text-shadow: none;
                        border: none;
                        color: white;
                        padding: 10px;
                        min-width: 100px;
                        min-height: 1em;
                        font-weight: 400;
                        line-height: 1.42857143;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        margin-bottom: 4px;
                        margin-left: 2px;
                        margin-right: 2px;

                        &:focus,
                        &:focus-visible{
                            outline: none;
                        }
                        &:hover {
                            background-color: #bdc3c7;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.shakeBox  {
    animation: uiconfirm-shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transition-duration: 0.4s;
    transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
    transition-property: all, margin;
}

.exitBox {
    animation-name: uiconfirm-close;
    animation-duration: .4s;
}

.enetrBox {
    animation-name: uiconfirm-enter;
    animation-duration: .4s;
}



@keyframes uiconfirm-shake {
    10%,
    90% {
        -webkit-transform: translate3d(-2px, 0, 0);
        transform: translate3d(-2px, 0, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-8px, 0, 0);
        transform: translate3d(-8px, 0, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(8px, 0, 0);
        transform: translate3d(8px, 0, 0);
    }
}
 
@keyframes uiconfirm-enter {
    0% {
        transform: translateY(-800px);
        opacity: 0.2;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes uiconfirm-close {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}